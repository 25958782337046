

.rmdp-calendar {
    height: max-content;
    padding: 0px;
    box-shadow: #fff;
}

.rmdp-shadow {
    box-shadow: 0 0 0px #fff;
}

.rmdp-header-values  {
    color: gray;
    font-family: "Nunito", sans-serif;
    border-radius: 50%;
    font-size: 13px;
    line-height: 28px;
    font-weight: bold;
}

.rmdp-day span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: gray;
}

.rmdp-week-day {
    color: gray;
    font-family: "Nunito", sans-serif;
    border-radius: 50%;
    font-size: 13px;
    line-height: 28px;
    font-weight: bold;
}

.rmdp-arrow-container {
    border-radius: 0%;
    cursor: pointer;
    display: flex;
    height: 0px;
    justify-content: center;
    margin: 0 0px;
    width: 0px;
}

.rmdp-arrow {
    border: solid #fff;
    border-width: 0 0px 0px 0;
    display: inline-block;
    height: 0px;
    margin-top: 0px;
    padding: 0px;
    width: 0px;
}

.rmdp-week {
    margin-right: 20px;
}
