
:root {
    --bg-card: #ffffff;
    --bg:  #ffffff;
    --bg-accent: #484a4d;
    --text-color: #838C96;
    --nav-size: 60px;
    --border: 1px solid #cfcfcf;
    --border-radius: 8px;
    --speed: 500ms;
    --icon-sm: 0.875em;
    --icon-lg: 1.25em;
    --icon-xl: 1.5em;
    --icon-2xl:2em;
}



.icon-button-lg {
    --button-size: calc(var(--icon-lg) * 1.3);
    /* border: #242526 1px solid; */
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--bg);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}
.icon-button-lg:hover{
    filter: brightness(1);
}


a.normal-text-anchor {
    color: var(--text-color);
    text-decoration: none;
}
a.normal-text-anchor:hover {
    color: var(--text-color);
    text-decoration: none;
}
.icon-button-lg svg {
    fill: var(--text-color);
    width: var(--icon-lg);
    height: var(--icon-lg);
}
.dropdown-menu-lg {
    position: absolute;
    top: calc(var(--button-size) - 2px);
    width: 300px;
    transform: translate(-45%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;

}
/* 😊 
    #838C96
*/