.detalle-ticket-header {
    background-color:#f1f3fa
  }
  .detalle-ticket-body {
    border:1px solid #f1f3fa
  }

hr.separador {
    border: none;
    height: 1px;
    /* Set the hr color */
    border:1px solid #c8cacf;
    background-color: #c8cacf; /* Modern Browsers */
    max-width: 65%;
}