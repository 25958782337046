.box-shadow {
    transition: box-shadow .3s;
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
    
  }
  .box-shadow:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }